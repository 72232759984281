import {FC} from 'react';
import {MedAIChatbot, PrescriberAI} from '@/components';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useShowMedAi} from '@/hooks/useShowMedAi';

export interface IChatbotProps {}

const Chatbot: FC<IChatbotProps> = () => {
  const {setId, drugName, genericName, names} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();

  const showMedAi = useShowMedAi(setId);

  return showMedAi ? (
    <MedAIChatbot
      drugName={drugName}
      genericName={genericName}
      triggerId='med_ai_wc_button'
    />
  ) : (
    <PrescriberAI
      defaultExpanded={!isMobileOrTablet}
      triggerId='aiWC_button'
      drugName={drugName}
      drugsName={names}
      hideHyperlinks
    />
  );
};

export default Chatbot;
