import Chatbot from '../Chatbot';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useCurrentSlug, useEnhanced, useRenderInIFrame} from '@/hooks';
import { useIsPfizerDrug } from '@/hooks/useIsPfizerDrug';

const Ai = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced, disableAi} = useEnhanced(setId);
  const pdpIsEmbedded = useRenderInIFrame();

  const isPfizerDrug = useIsPfizerDrug();
  const showAIPPT = useFlag(FLAGS.PPT_AI_COMPONENT) && !isPfizerDrug;
  const hideChatbotForNoEnhanced = useFlag(FLAGS.HIDE_CHATBOT_FOR_NO_ENHANCED);

  return showAIPPT &&
    !pdpIsEmbedded &&
    (isEnhanced || !hideChatbotForNoEnhanced) &&
    !disableAi ? (
    <Chatbot />
  ) : (
    <>
      <div
        id='ppt-pdp-large-ad-container'
        className='hidden h-auto w-full md:block'>
        <div className='hidden' />
      </div>
      <div
        id='ppt-pdp-medium-ad-container-mobile'
        className='h-auto w-full md:hidden'>
        <div className='hidden' />
      </div>
    </>
  );
};

export default Ai;
